const businessList = [
    "Automotive",
    "Banking & Financial Services",
    "Construction and Engineering",
    "e-commerce",
    "e-learning",
    "Energy & Utilities",
    "Healthcare",
    "Insurance",
    "Logistics",
    "Media & Entertainment",
    "Retail",
    "Technology",
    "Travel & Hospitality",
]

export default businessList;