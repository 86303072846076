import React, { useState, useRef } from "react"
import arrow_down from "../../assets/img/icons/arrow-down-sm-black.svg"

const SelectBox = ({
  style,
  divClass,
  dropdownList,
  link = false,
  searchTxt = "Search",
  handleSelectBox,
  selectBoxValue,
  fieldError,
  name,
  label,
  selectClass = "",
  hideSearch = false,
  hideLabel = false,
  headerStyle = {},
  topPosition,
}) => {
  const [showDrowpDown, setShowDropDown] = useState(false)
  const [search, setSearch] = useState("")
  const [value, setValue] = useState("")

  const txtSearch = useRef(null)
  const popupBox = useRef(null)

  const handleHeaderClick = () => {
    if (!hideSearch) {
      setTimeout(() => {
        txtSearch.current.focus()
      }, 200)
    }
    setShowDropDown(!showDrowpDown)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleHeaderKeyDown = e => {
    // check keys if you want
    if (e.keyCode === 13) {
      setShowDropDown(!showDrowpDown)
    }
  }

  const handleSearchKeyDown = e => {
    // check keys if you want

    if (e.keyCode === 13) {
      e.preventDefault()
      return false
    } else if (e.keyCode === 40) {
      if (items) {
        document.querySelector(`.${name}-selectbox-0`).focus()
      }
    }
  }

  const handleLiClick = e => {
    setValue(e.target.id)
    setSearch("")
    setShowDropDown(!showDrowpDown)
    handleSelectBox({ id: e.target.id, name: name })
  }

  const handleKeyDownLi = e => {
    e.preventDefault()
    const { dataset, id } = e.target
    let dataCounter = parseInt(dataset.counter)

    if (e.keyCode === 13) {
      setValue(id)
      setSearch("")
      setShowDropDown(!showDrowpDown)
      handleSelectBox({ id: id, name: name })
    } else if (e.keyCode === 40) {
      let ctr

      if (dataCounter >= items.length - 1) {
        ctr = 0
      } else {
        ctr = dataCounter + 1
      }

      document.querySelector(`.${name}-selectbox-${ctr}`).focus()
    } else if (e.keyCode === 38) {
      let ctr
      if (dataCounter === 0) {
        ctr = items.length - 1
      } else {
        ctr = dataCounter - 1
      }
      document.querySelector(`.${name}-selectbox-${ctr}`).focus()
    } else {
      txtSearch.current.focus()
    }
  }

  const items = dropdownList
    .filter(data => data.toUpperCase().includes(search.toUpperCase()))
    .map((data, index) => {
      let country_link = ""
      switch (data) {
        case "Bahrain":
          country_link = "https://gulf.fms-tech.com/Bahrain/"
          break
        case "Egypt":
          country_link = "https://egypt.fms-tech.com/EgyptPortal/"
          break
        case "Iraq":
          country_link = "https://gulf.fms-tech.com/Iraq/"
          break
        case "Kuwait":
          country_link = "https://gulf.fms-tech.com/kuwait/"
          break
        case "Pakistan":
          country_link = "https://asia.fms-tech.com/Pakistan/"
          break
        case "Qatar":
          country_link = "https://gulf.fms-tech.com/Qatar/"
          break
        case "Saudi Arabia":
          country_link = "https://gulf.fms-tech.com/ksa/"
          break
        case "Serbia":
          country_link = "https://eu.fms-tech.com/Serbia/"
          break
        case "Tunisia":
          country_link = "https://tunis.fms-tech.com/Portal/"
          break

        default:
          country_link = "https://uae.fms-tech.com/BBIS/"
          break
      }

      if (link) {
        return (
          <li
            className={`select-box-li ${name}-selectbox-${index}`}
            key={data}
            name={name}
          >
            <a href={country_link}>{data}</a>
          </li>
        )
      }

      return (
        <li
          className={`select-box-li ${
            !link && "for-form"
          } ${name}-selectbox-${index}`}
          aria-hidden="true"
          key={data}
          id={data}
          name={name}
          data_value={data}
          onClick={handleLiClick}
          onKeyDown={handleKeyDownLi}
          type="selectbox"
          tabIndex={index}
          data-counter={index}
        >
          {data}
        </li>
      )
    })

  return (
    <div
      className={`field-container light-border ${
        fieldError && !selectBoxValue && "error"
      } ${showDrowpDown && "active"} ${divClass} ${style}`}
      id="smartMobilityContainer"
    >
      {!hideLabel && (
        <label className={selectBoxValue && "always-active"} htmlFor={name}>
          {label}
        </label>
      )}
      <div className="select-box-container">
        {showDrowpDown && (
          <div
            className="selext-box-overlay"
            onClick={() => setShowDropDown(false)}
            aria-hidden
          ></div>
        )}
        <header
          className={`select-box-header ${selectClass} ${
            value !== "" || selectBoxValue ? "active" : ""
          }`}
          aria-hidden="true"
          id="selectBoxHeader"
          onClick={handleHeaderClick}
          onKeyDown={handleHeaderKeyDown}
          style={headerStyle}
        >
          <span>{selectBoxValue}</span>
          <img
            loading="lazy"
            className="right-icon"
            src={arrow_down}
            alt="Select box Dropdown"
          />
        </header>
        <div
          className={`select-box-list-container full-width ${
            topPosition && "top-position"
          } ${showDrowpDown && "showme"}`}
          id="selectBoxListContainer"
        >
          {!hideSearch && (
            <div className="field-container light-border active">
              <input
                type="text"
                name="selectBoxSearch"
                value={search}
                placeholder={searchTxt}
                onChange={handleSearch}
                ref={txtSearch}
                className="normal-field"
                onKeyDown={handleSearchKeyDown}
                autoComplete="off"
              />
            </div>
          )}

          <ul
            className={`select-box-list ${!link && "for-form"}`}
            id="selectBoxList"
            ref={popupBox}
          >
            {items}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SelectBox
