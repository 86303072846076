import React, {Fragment,
  useState,
  useEffect,
  useContext,
  useRef
} from 'react'
import Seo from "../components/seo/seo";
import PageBanner from "../components/common/PageBanner";
import Layout from '../components/layout/layout';
import Clients from "../components/common/Clients";

import { GlobalStateContext } from "../context/GlobalContextProvider";
import icon1 from '../assets/img/icons/icon-1.jpg'
import icon2 from '../assets/img/icons/icon-2.jpg'
import icon3 from '../assets/img/icons/icon-3.jpg'
import icon4 from '../assets/img/icons/icon-4.jpg'

import TextBox from "../components/inputs/Textbox";
import PhoneNumber from "../components/inputs/PhoneNumber";
import TextArea from "../components/inputs/TextArea";
import SelectBox from "../components/inputs/SelectBox";

import countryList from '../components/json/countryList'
import businessList from '../components/json/businessList'

const Partner = () => {
  const [txtValues, setTxtValues] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [selectValues, setSelectValues] = useState({
    business:"",
    country:"",
  })

  // const [errors, setErrors] = useState([]);
  const [errors] = useState([]);
  // const [sending, setSending] = useState(false);
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTxtValues({ ...txtValues, [name]: value });
  };

  const handleSelectChange = e => {
    setSelectValues({ ...selectValues, [e.name]: e.id })
  }

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <Layout>
        <Seo
          title='Become Our Partner | VIRSAT - Virtual Reality Safety Training'
          description='We are happy to provide information tailored to your company’s need. Please call us or send an email.'
        />

        <PageBanner
          title='Become Our Partner'
          // subtitle='We are more than happy to help'
          customClass='contact'
        />
        <section
          className='main-container partner-page hide-container'
          ref={container}
        >
          <div className='row top-pad bottom-pad two'>
            <div className='column'>
              <p className='no-mar'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              </p>
              <h2 className='main-title capitalize'>
                Why partner with us?
              </h2>
              <ul className="partner-why-list">
                <li>
                  <img src={icon1} alt="Icon 1"/>
                  <div>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,</div>
                </li>
                <li>
                  <img src={icon2} alt="Icon 2"/>
                  <div>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,</div>
                </li>
                <li>
                  <img src={icon3} alt="Icon 3"/>
                  <div>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,</div>
                </li>
                <li>
                  <img src={icon4} alt="Icon 4"/>
                  <div>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,</div>
                </li>
              </ul>
              <p className="medium">Contact us to explore partnership opportunities and join our growing Channel Partner network.</p>
            </div>
            <div className='column show-overflow'>
              <p className='no-mar'>
                If you are interested to become <span className='primary-color'><strong>VIRSAT</strong></span> partner, please fill in the form below. One representative of our team will contact you shortly.
              </p>

              <div className='full-width'>
                <form action='' method='post' onSubmit={handleSubmit}>
                  <div className='label-container'>
                    <label className='' htmlFor='name'>
                      Name*
                    </label>
                    <TextBox
                      type='text'
                      id='name'
                      name='name'
                      placeholder='Eg. John Doe'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.name}
                      required
                      error={errors.name}
                    />
                    {errors.name && (
                      <div className='field-error'>{errors.name[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='job_title'>
                      Job Title*
                    </label>
                    <TextBox
                      type='text'
                      id='job_title'
                      name='job_title'
                      placeholder='Eg. Manager'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.job_title}
                      required
                      error={errors.job_title}
                    />
                    {errors.job_title && (
                      <div className='field-error'>{errors.job_title[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='company'>
                      Company Name*
                    </label>
                    <TextBox
                      type='text'
                      id='company'
                      name='company'
                      placeholder='Eg. ABC Company'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.company}
                      required
                      error={errors.company}
                    />
                    {errors.company && (
                      <div className='field-error'>{errors.company[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='website'>
                      Company Website
                    </label>
                    <TextBox
                      type='text'
                      id='website'
                      name='website'
                      placeholder='Eg. www.acb-company.com'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.website}
                      error={errors.website}
                    />
                    {errors.website && (
                      <div className='field-error'>{errors.website[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='business'>
                      Your main business*
                    </label>
                    <SelectBox
                      dropdownList={businessList}
                      label="Select Business"
                      searchTxt="Search Business"
                      id="business"
                      name="business"
                      selectClass="regular-text"
                      headerStyle={{width:'100%'}}
                      handleSelectBox={handleSelectChange}
                      selectBoxValue={selectValues.business}
                      required
                    />
                    {errors.business && (
                      <div className='field-error'>{errors.business[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='phone'>
                      Phone
                    </label>
                    <PhoneNumber
                      name='phone'
                      id='phone'
                      value={txtValues.phone}
                      onChange={(phone) =>
                        setTxtValues({ ...txtValues, phone: phone })
                      }
                      txtBoxClass='regular-text'
                    />
                    {errors.phone && (
                      <div className='field-error'>{errors.phone[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='email'>
                      Email*
                    </label>
                    <TextBox
                      type='email'
                      id='email'
                      name='email'
                      placeholder='Eq. johndoe@yourcompany.com'
                      txtBoxClass='regular-text'
                      onChange={handleInputChange}
                      value={txtValues.email}
                      error={errors.email}
                      required
                    />
                    {errors.email && (
                      <div className='field-error'>{errors.email[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='message'>
                      Comments and Questions
                    </label>
                    <TextArea
                      name='message'
                      id='message'
                      placeholder='Enter your comments/questions here...'
                      onChange={handleInputChange}
                      value={txtValues.message}
                      error={errors.message ? true : false}
                      txtAreaClass='regular-text'
                    />
                    {errors.message && (
                      <div className='field-error'>{errors.message[0]}</div>
                    )}
                  </div>
                  <div className='label-container'>
                    <label className='' htmlFor='country'>
                      Country*
                    </label>
                    <SelectBox
                      dropdownList={countryList}
                      label="Select Country"
                      searchTxt="Search Country"
                      id="country"
                      name="country"
                      selectClass="regular-text"
                      headerStyle={{width:'100%'}}
                      handleSelectBox={handleSelectChange}
                      selectBoxValue={selectValues.country}
                      required
                    />
                    {errors.country && (
                      <div className='field-error'>{errors.country[0]}</div>
                    )}
                  </div>
                  <button type='submit'>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Clients />
      </Layout>
    </Fragment>
  )
}

export default Partner
